import { Flex } from '@applyboard/crystal-ui';

import { ActionGroup } from './ActionGroup';
import { useApplicationProcessing } from './useApplicationProcessing';
import { Application, ApplicationStatus } from '../../data/useApplication';

type ApplicationProcessingActionsProps = { application: Application };
export function ApplicationProcessingActions(props: ApplicationProcessingActionsProps) {
  const {
    application: { status },
  } = props;

  const { passScreening } = useApplicationProcessing(props.application.id);

  function onAction(status: ApplicationStatus['code']) {
    switch (status) {
      case 'READY_FOR_ASSESSMENT':
        return passScreening();
      default:
        return Promise.resolve(); // TODO for now
    }
  }

  return (
    <Flex direction="row" gap={1}>
      {status.actionGroups.map((actionGroup) => (
        <ActionGroup key={actionGroup.title} actionGroup={actionGroup} onAction={onAction} />
      ))}
    </Flex>
  );
}
