import { RouteObject, useMatches as baseUseMatches } from 'react-router-dom';

import { ErrorPage } from '~/pages/ErrorPage/index';
import { PersonListOutline, IconType } from './icons';
import { ApplicationsPage } from './pages/Applications';
import { ApplicationDetailsPage } from './pages/ApplicationDetails';

export type Route = {
  name: string;
  path: string;
  crumb?: string;
  icon: IconType;
  hidden?: boolean;
  children?: Route[];
  page: React.ReactNode;
};

export const routes: Route[] = [
  {
    name: 'Applications',
    crumb: 'Applications',
    icon: PersonListOutline,
    path: '/applications',
    page: <ApplicationsPage />,
    children: [
      {
        name: 'Application Detail',
        crumb: 'Application Detail',
        path: ':id',
        icon: PersonListOutline,
        page: <ApplicationDetailsPage />,
      },
    ],
  },
];

export const reactRouterV6Routes = routes.map((route) => convertToReactV6(route));

export function useMatches() {
  const matches = baseUseMatches();
  return matches.filter((m) => m.handle).map((m) => m.handle as Route);
}

function convertToReactV6(route: Route): RouteObject {
  if (route.children && route.children.length) {
    return {
      path: route.path,
      handle: route,
      children: [
        {
          path: '',
          index: true,
          element: route.page,
          errorElement: <ErrorPage errorCode={500} />,
        },
        ...route.children.map((childRoute) => convertToReactV6(childRoute)),
      ],
    };
  } else {
    return {
      handle: route,
      path: route.path,
      element: route.page,
      errorElement: <ErrorPage errorCode={500} />,
      children: route.children?.map((childRoute) => convertToReactV6(childRoute)),
    };
  }
}
