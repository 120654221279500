function generate(tempalte: string): string {
  return tempalte.replace(/[xy]/g, (c) => {
    // eslint-disable-next-line no-bitwise
    const r = (Math.random() * 16) | 0;
    // eslint-disable-next-line no-bitwise
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function uuidv4() {
  return generate('xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx');
}
export function uidNoDash() {
  return generate('xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx');
}
