import { Button, Flex, MenuButton } from '@applyboard/crystal-ui';
import { AccountCircleFillIcon, LogoutOutlineIcon } from '@applyboard/ui-icons';
import React from 'react';
import { useAuth } from '~/lib/useAuth';

type AppbarProps = {
  logo: string;
  title: string;
};
export function AppBar(props: AppbarProps) {
  const [open, setOpen] = React.useState(false);
  const auth = useAuth();
  const logout = React.useCallback(async () => {
    await auth.logout();
  }, [auth]);

  return (
    <Flex direction="row" py={2} p={20} align="center" justify="between">
      <img alt={props.title} src={props.logo} />
      <Flex justify="start">
        <MenuButton open={open} alignment="right" onOpenChange={setOpen}>
          <MenuButton.Trigger>
            <Button
              size="md"
              variant="transparent"
              aria-label="Open menu"
              intent="primary"
              leadIcon={AccountCircleFillIcon}
            />
          </MenuButton.Trigger>
          <MenuButton.Button icon={LogoutOutlineIcon} id="logout_button" onClick={logout}>
            Log out
          </MenuButton.Button>
        </MenuButton>
      </Flex>
    </Flex>
  );
}
