import { GridAlpha, Flex } from '@applyboard/crystal-ui';
import { StudentOutlineIcon } from '@applyboard/ui-icons';
import { DetailsCard } from './DetailsCard';
import { formatDate } from '~/utils/formatDate';
import { LabelValue } from '~/components/uie-fix/LabelValue';

import type { EducationHistory } from '../data/useApplication';
import { AttachedDocuments } from './AttachedDocuments';

type EducationHistoryProps = { educationHistories: EducationHistory[] };
export function EducationHistory(props: EducationHistoryProps) {
  const { educationHistories } = props;

  return (
    <DetailsCard icon={StudentOutlineIcon} title="Education History">
      <Flex justify="start" direction="column" gap={10}>
        {educationHistories.map((eh, idx) => (
          <Flex key={idx} justify="start" direction="column" gap={8}>
            <GridAlpha rows="repeat(1, auto)" columnGap={2} rowGap={6} columns="repeat(3, 1fr)">
              <GridAlpha.Item rowStart={1} columnStart={1} columnEnd={3}>
                <LabelValue label="CREDENTIAL GAINED" variant="titleS" value={eh.credentialReceived} />
              </GridAlpha.Item>

              <GridAlpha.Item rowStart={2} columnStart={1} columnEnd={1}>
                <LabelValue label="EDUCATION LEVEL" value={eh.level.name} />
              </GridAlpha.Item>
              <GridAlpha.Item rowStart={2} columnStart={2} columnEnd={2}>
                <LabelValue label="EDUCATION COUNTRY" value={eh.country.countryLabel} />
              </GridAlpha.Item>

              <GridAlpha.Item rowStart={3} columnStart={1} columnEnd={1}>
                <LabelValue label="START DATE" value={formatDate(new Date(eh.startDate))} />
              </GridAlpha.Item>
              <GridAlpha.Item rowStart={3} columnStart={2} columnEnd={2}>
                <LabelValue label="END DATE" value={formatDate(new Date(eh.endDate))} />
              </GridAlpha.Item>
            </GridAlpha>
            <AttachedDocuments showTitle attachmentDocuments={eh.documents} />
          </Flex>
        ))}
      </Flex>
    </DetailsCard>
  );
}
