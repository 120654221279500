import { useQuery } from '@tanstack/react-query';
import { useRequest } from '~/lib/useRequest';
import { ApplicationSortableFields, ApplicationsPagePropsResponse } from '@ab/ams-school-api/types/private/v1';

import { config } from '~/config';
import { Intent } from '~/utils';

export type ReferenceData = {
  nationalities: Record<string, { alpha3Code: string; nationalityLabel: string; countryLabel: string }>;
  applicationStatuses: Record<string, { label: string; intent: Intent }>;
  intakeTerms: Record<string, { name: string; startsOn: string }>;
  pageSizes: number[];
};
export type InitialQueryParameter = {
  searchQuery?: string;
  pageSize: number;
  sort: ApplicationSortableFields;
};
export type ApplicationsPageInitialProps = ApplicationsPagePropsResponse;

export function useApplicationsPageProps() {
  const request = useRequest();
  const { isLoading, data } = useQuery({
    queryKey: ['useApplicationsPageProps'],
    queryFn: async () => {
      const response = await request<ApplicationsPagePropsResponse>(
        `${config.apiHost}/private/v1/applications/page_props`,
        {
          isExpectedResponse,
        }
      );

      return response;
    },
  });

  return {
    initialPropsError: false,
    isLoadingInitialProps: isLoading,
    initialProps: data,
  };
}

function isExpectedResponse(res: unknown): res is ApplicationsPagePropsResponse {
  return Boolean(res && typeof res === 'object');
}
