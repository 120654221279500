import { Flex, Text, Tag } from '@applyboard/crystal-ui';
import { Application } from '~/pages/ApplicationDetails/data/useApplication';

type ApplicationDetailsHeadingProps = { application: Application };
export function ApplicationDetailsHeading(props: ApplicationDetailsHeadingProps) {
  return (
    <Flex direction="column">
      <Flex pl={4} justify="between" align="center">
        <Flex align="center" gap={4} pb={2}>
          <Text align="left" contrast="high" intent="secondary" variant="headlineM">
            Application Details
          </Text>
          <Tag icon="fill" intent={props.application.status.intent} size="md">
            {props.application.status.label}
          </Tag>
        </Flex>
      </Flex>
    </Flex>
  );
}
