import React from 'react';

const FourOThree = ({ customMessage }: { customMessage?: string }) => (
  <>
    <h2>ERROR 403</h2>
    <h1>Access Denied</h1>
    <p>{customMessage ? customMessage : 'You are not authorized to access this page.'}</p>
  </>
);

export default FourOThree;
