import React from 'react';
import { MultiCombobox } from '~/components/uie-fix/MultiCombobox';
import { useFilterField } from '../../hooks/useFilterField';
import { useStaticProps } from '../../context';

export const IntakeTermFilter = () => {
  const intakeTermFilter = useFilterField('intakeTermId', 'PrimaryFilter');
  const {
    referenceData: { intakeTerms },
  } = useStaticProps();

  return (
    <MultiCombobox
      value={intakeTermFilter.value}
      aria-label="Intake Term"
      placeholder="Intake Term"
      onChange={(selectedItems) => intakeTermFilter.setValue(selectedItems.filter((s) => !!s.trim()))}
      placeholderVariant="indicator"
    >
      {Object.entries(intakeTerms).map(([id, { name }]) => (
        <MultiCombobox.Option label={name} value={id} key={id} />
      ))}
    </MultiCombobox>
  );
};
