/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react/display-name */
import React from 'react';
import { Link } from 'react-router-dom';

export const CustomLink = React.forwardRef((props: any, ref) => {
  const { href, ...restProps } = props;
  return href[0] === '/' ? <Link to={href} ref={ref} {...restProps} /> : <a ref={ref} href={href} {...restProps} />;
});
