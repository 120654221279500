import { PhoneOutlineIcon } from '@applyboard/ui-icons';
import { GridAlpha, Flex, Heading, Text } from '@applyboard/crystal-ui';
import { DetailsCard } from './DetailsCard';
import { LabelValue } from '~/components/uie-fix/LabelValue';
import { Applicant } from '../data/useApplication';

type ContactInformationProps = {
  contactInfo: Applicant['contactInformation'];
  emergencyContact: Applicant['emergencyContact'];
};
export function ContactInformation(props: ContactInformationProps) {
  const { contactInfo, emergencyContact } = props;

  return (
    <DetailsCard icon={PhoneOutlineIcon} title="Contact Information">
      <Flex justify="start" direction="column">
        <GridAlpha rows="repeat(3, auto)" columnGap={2} rowGap={6} columns="repeat(2, 1fr)">
          <LabelValue label="PHONE NUMBER" value={contactInfo.phoneNumber} />
          <LabelValue label="ALTERNATIVE PHONE NUMBER" value={contactInfo.alternatePhoneNumber || 'Not provided'} />
          <LabelValue label="EMAIL" value={contactInfo.email} />
        </GridAlpha>

        <Flex direction="column" gap={emergencyContact ? 8 : 1}>
          <Heading level={4} variant="titleS">
            Emergency Contact
          </Heading>
          {emergencyContact ? (
            <GridAlpha rows="repeat(3, auto)" columnGap={2} rowGap={6} columns="repeat(2, 1fr)">
              <LabelValue label="NAME" value={emergencyContact.name} />
              <LabelValue label="RELATIONSHIP" value={emergencyContact.relationship.name} />
              <GridAlpha.Item rowStart={2} rowEnd={2}>
                <LabelValue label="PHONE NUMBER" value={emergencyContact.phoneNumber} />
              </GridAlpha.Item>
              <GridAlpha.Item rowStart={3} rowEnd={3}>
                <LabelValue label="EMAIL" value={emergencyContact.email} />
              </GridAlpha.Item>
            </GridAlpha>
          ) : (
            <Text variant="bodyM" contrast="low">
              Not Provided
            </Text>
          )}
        </Flex>
      </Flex>
    </DetailsCard>
  );
}
