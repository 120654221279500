import { QueryParameterSchema } from './types';

export const programIdFilterQueryFilter: QueryParameterSchema<'programId'> = {
  getDefaultValue: () => [],
  getValue: ({ queryParameters }) => queryParameters.programId,

  fromUrlSearchParams: ({ urlSearchParams }) => ({
    programId: urlSearchParams.get('filter[programId]')?.split(',') || [],
  }),
  toUrlSearchParams: ({ value }) => ({ 'filter[programId]': value.join(',') }),
  toSearchQuery: ({ value }) => {
    if (value.length === 0) return {};
    return { 'filter[programId]': value };
  },
  getSummaryItems: ({ programs, value }) => {
    return value.map((id) => {
      const program = programs.find((p) => p.id === id);
      if (!program) {
        return {
          filterKey: 'programId',
          key: `program_${id}`,
          label: `Loading...`,
          value: id,
        };
      }
      return {
        filterKey: 'programId',
        key: `program_${id}_loading`,
        label: `${program.name}(${program.code})`,
        value: id,
      };
    });
  },
  removeParam: ({ value, removedValue }) => {
    return { programId: value.filter((item) => item !== removedValue) };
  },
};
