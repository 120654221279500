import { QueryParameterSchema } from './types';

export const statusFilterQueryFilter: QueryParameterSchema<'status'> = {
  getDefaultValue: () => [],
  getValue: ({ queryParameters }) => queryParameters.status,

  fromUrlSearchParams: ({ urlSearchParams }) => ({
    status: (urlSearchParams.get('filter[status]') || '').split(','),
  }),
  toUrlSearchParams: ({ value }) => ({ 'filter[status]': value.join(',') }),
  toSearchQuery: ({ value }) => {
    if (value.length === 0) return {};
    return { 'filter[status]': value };
  },
  sanitize: ({ value, staticProps }) => {
    const {
      referenceData: { applicationStatuses },
    } = staticProps;
    return { status: value.filter((s) => !!s && !!applicationStatuses[s]) };
  },
  getSummaryItems: ({
    value,
    staticProps: {
      referenceData: { applicationStatuses },
    },
  }) => {
    return value.map((status) => ({
      filterKey: 'status',
      key: `status_${status}`,
      label: applicationStatuses[status].label,
      value: status,
    }));
  },
  removeParam: ({ value, removedValue }) => {
    return { status: value.filter((item) => item !== removedValue) };
  },
};
