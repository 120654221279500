import { QueryParameterSchema } from './types';

export const nationalityFilterQueryFilter: QueryParameterSchema<'nationality'> = {
  getDefaultValue: () => [],
  getValue: ({ queryParameters }) => queryParameters.nationality,

  fromUrlSearchParams: ({ urlSearchParams }) => ({
    nationality: (urlSearchParams.get('filter[nationality]') || '').split(','),
  }),
  toUrlSearchParams: ({ value }) => ({ 'filter[nationality]': value.join(',') }),
  toSearchQuery: ({ value }) => {
    if (value.length === 0) return {};
    return { 'filter[nationality]': value };
  },
  sanitize: ({ value, staticProps }) => {
    const {
      referenceData: { nationalities },
    } = staticProps;
    return { nationality: value.filter((s) => !!s && !!nationalities[s]) };
  },
  getSummaryItems: ({
    value,
    staticProps: {
      referenceData: { nationalities },
    },
  }) => {
    return value.map((nationality) => ({
      filterKey: 'nationality',
      key: `nationality_${nationality}`,
      label: nationalities[nationality]?.nationalityLabel || 'unknown',
      value: nationality,
    }));
  },
  removeParam: ({ value, removedValue }) => {
    return { nationality: value.filter((item) => item !== removedValue) };
  },
};
