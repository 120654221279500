import { useSession } from './../context';

interface Event {
  event: string;
  data?: Record<string, unknown>;
}
interface ErrorEvent extends Event {
  event: string;
  error?: Error;
  data?: Record<string, unknown>;
}

type Group = 'Search Result' | 'Filter Summary' | 'Advanced Filter' | 'Filter Parameters';

export const useEventTracking = (group: Group) => {
  const sessionId = useSession();

  // TODO: we should use ui-utils event tracker
  function uiUtilsTrackEvent(event: unknown) {
    // eslint-disable-next-line no-console
    console.log('EVENT-TRACKER:', event);
  }

  const trackError = (props: ErrorEvent) => {
    uiUtilsTrackEvent({
      group,
      data: {
        ...props.data,
        sessionId,
      },
      error: props.error,
      event: getEvent(props.event),
    });
  };
  const trackEvent = (props: Event) => {
    uiUtilsTrackEvent({
      group,
      data: {
        ...props.data,
        sessionId,
      },
      event: getEvent(props.event),
    });
  };

  return { trackEvent, trackError };
};

const getEvent = (title: string) => `Search - ${title}`;
