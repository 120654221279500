import { useSchema } from './useSchema';
import { QueryParametersKey, QueryParametersValue } from '../schema';
import { ChangeSource, useStaticProps, useQueryParameter } from '../context';

export function useFilterField<FilterKey extends QueryParametersKey>(filterKey: FilterKey, source: ChangeSource) {
  const schema = useSchema();

  const staticProps = useStaticProps();
  const defaultValue = schema.getDefaultValue(filterKey);
  const { setQueryParameters } = useQueryParameter();
  const value = schema.getValue(filterKey);

  const setValue = (newValue: QueryParametersValue<FilterKey>) => {
    setQueryParameters(source, { pageNumber: 1, [filterKey]: newValue });
  };

  return { setValue, value, defaultValue, staticProps };
}
