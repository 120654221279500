type JSONObject = {
  [key: string]: string | number | boolean | null | JSONObject | JSONArray;
};
interface JSONArray extends Array<JSONObject | string | number | boolean | null> {}

export class NetworkError extends Error {
  name = 'NetworkError';
  status?: number;

  constructor() {
    super('A network error occurred.  There may be a problem with the internet connection');
  }
}

export class HttpError extends Error {
  name = 'HttpError';
  status: number;
  response: Response | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  body: any | undefined;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(status: number, message: string, response?: Response, body?: any) {
    super(message);
    this.status = status;
    this.response = response;
    this.body = body;
  }

  public static of(err: unknown) {
    if (err instanceof HttpError) {
      return err as HttpError;
    } else if (err instanceof NetworkError) {
      return new HttpError(err.status || 500, err.message, undefined);
    } else if (err instanceof UnexpectedResponseError) {
      return new HttpError(err.status || 500, err.message, undefined);
    } else if (err instanceof Error) {
      return new HttpError(500, err.message);
    }
    return new HttpError(500, 'Unexpected Error');
  }
}

export class UnexpectedResponseError extends Error {
  name = 'UnexpectedResponseError';
  status: number;

  constructor(status: number, message: string) {
    super(message);
    this.status = status;
  }
}

export type GenericError = NetworkError | HttpError | UnexpectedResponseError;
