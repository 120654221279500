import { HttpError } from '~/lib/useRequest';
import { usePatchApplication } from '../../data/usePatchApplication';
import { useToasts } from '@applyboard/crystal-ui';

export function useApplicationProcessing(applicationId: string) {
  const { negative } = useToasts();
  const { patchApplication } = usePatchApplication(applicationId);

  async function passScreening(): Promise<void> {
    await patchApplication({ status: 'READY_FOR_ASSESSMENT' }).catch((err) => {
      if (HttpError.of(err).message === 'invalid status') {
        negative(new Error('Application is already screened, please refresh the page'));
      }
      throw err;
    });
  }

  return {
    passScreening,
  };
}
