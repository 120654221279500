export const tryParseNumber = (value: string | number | null | undefined) => {
  if (typeof value === 'number') return value;

  if (value == null) return null;
  if (!/^\d+$/.test(value)) return null;
  return parseInt(value);
};

export const tryParseFloat = (value: string | null) => {
  if (value == null) return null;
  if (!/^\d+(\.\d+)?$/.test(value)) return null;
  return parseFloat(value);
};
