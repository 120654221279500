import React from 'react';

import FourOOne from './401';
import FourOThree from './403';
import FourOFour from './404';
import FourTwoTwo from './422';
import FiveHundred from './500';

type IErrorCodesString = '404' | '401' | '403' | '422' | '500';
type IErrorCodesNumber = 404 | 401 | 403 | 422 | 500;
type IErrorCodes = IErrorCodesString | IErrorCodesNumber;

function getErrorPage(errorCode?: IErrorCodes) {
  const code = errorCode || 500;
  switch (Number(code)) {
    case 404:
      return FourOFour;
    case 422:
      return FourTwoTwo;
    case 401:
      return FourOOne;
    case 403:
      return FourOThree;
    default:
      return FiveHundred;
  }
}

interface IErrorPageProps {
  errorCode?: IErrorCodes;
  customMessage?: string;
}

export const ErrorPage = ({ errorCode, customMessage }: IErrorPageProps) => {
  const Page = getErrorPage(errorCode);

  return <Page customMessage={customMessage} />;
};
