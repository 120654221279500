import { Flex } from '@applyboard/crystal-ui';

import { PageHeader } from '../../../components/PageHeader';
import {
  ApplicationsTable,
  QueryFilter,
  ApplicationsPagination,
  FilterSummary,
  StatusFilter,
  NationalityFilter,
  SubmissionDateFilter,
  ProgramFilter,
  IntakeTermFilter,
} from '../components';

export function DesktopLayout() {
  return (
    <Flex p={10} gap={3} direction="column">
      <PageHeader title="Applications" />
      <Flex gap={2}>
        <Flex.Item grow={1}>
          <QueryFilter />
        </Flex.Item>
        <Flex.Item grow={1}>
          <Flex gap={2} align="start" justify="start">
            <Filter as={<StatusFilter />} />
            <Filter as={<ProgramFilter />} />
            <Filter as={<IntakeTermFilter />} />
            <Filter as={<NationalityFilter />} />
            <Filter as={<SubmissionDateFilter />} />
          </Flex>
        </Flex.Item>
      </Flex>
      <Flex pt={2}>
        <FilterSummary />
      </Flex>
      <ApplicationsTable />
      <ApplicationsPagination />
    </Flex>
  );
}

function Filter(props: { as: React.ReactNode }) {
  return <div style={{ width: '250px' }}>{props.as}</div>;
}
