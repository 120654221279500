import { QueryParameterSchema } from './types';
import { SortableFields } from '../../data/useApplications';

export const sortableFields: SortableFields[] = [
  'programIntake.intakeTerm.startDate',
  '-programIntake.intakeTerm.startDate',
  'programIntake.program.name',
  '-programIntake.program.name',
  'status',
  '-status',
  'submittedAt',
  '-submittedAt',
];

export const sortFilterQueryFilter: QueryParameterSchema<'sort'> = {
  getDefaultValue: ({ initialQueryParameters }) => initialQueryParameters.sort,
  getValue: ({ queryParameters }) => queryParameters.sort,

  fromUrlSearchParams: ({ urlSearchParams, defaultValue }) => ({
    sort: (urlSearchParams.get('sort') as SortableFields) || defaultValue,
  }),
  toUrlSearchParams: ({ value }) => ({ sort: value }),
  toSearchQuery: ({ value }) => {
    if (!value) return {};
    return { sort: value as SortableFields };
  },
  sanitize: ({ value, defaultValue }) => {
    return { sort: sortableFields.includes(value as SortableFields) ? value : defaultValue };
  },
};
