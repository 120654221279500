import { formatDate } from '~/utils';
import { BookFillIcon } from '@applyboard/ui-icons';
import { LabelValue } from '~/components/uie-fix/LabelValue';
import { Divider, Flex, Text, GridAlpha } from '@applyboard/crystal-ui';
import { DetailsCard } from './DetailsCard';
import { AttachedDocuments } from './AttachedDocuments';
import type { LanguageProficiency } from '../data/useApplication';

type LanguageProficiencyProps = { languageProficiency: LanguageProficiency };
export function LanguageProficiency(props: LanguageProficiencyProps) {
  const { languageProficiency } = props;

  return (
    <DetailsCard icon={BookFillIcon} title="Language Proficiency">
      {languageProficiency.status === 'DONT_NEED' && (
        <Text variant="bodyM" contrast="low">
          N/A
        </Text>
      )}
      {languageProficiency.status === 'WILL_HAVE' && (
        <Text variant="bodyM" contrast="low">
          To be provided.
        </Text>
      )}
      {languageProficiency.status === 'HAVE' && (
        <Flex gap={6} direction="column">
          <LabelValue
            label="Test type"
            value={languageProficiency.languageProficiencyData.testType.title}
            variant="titleS"
          />
          <Flex gap={10}>
            <LabelValue
              label="Test Date"
              value={formatDate(new Date(languageProficiency.languageProficiencyData.testDate))}
            />
            <Flex.Item>
              <LabelValue
                label={languageProficiency.languageProficiencyData.certificateNumber.title}
                value={languageProficiency.languageProficiencyData.certificateNumber.value}
              />
            </Flex.Item>
          </Flex>
          <Divider />
          <LabelValue
            label={languageProficiency.languageProficiencyData.overallScore.title}
            value={languageProficiency.languageProficiencyData.overallScore.value}
            variant="labelL"
            color="primary"
          />
          <GridAlpha rows="repeat(1, auto)" columnGap={2} rowGap={6} columns="repeat(5, 1fr)">
            <>
              {languageProficiency.languageProficiencyData.subscores.map((s) => (
                <LabelValue key={s.title} label={s.title} value={s.formattedValue} variant="titleS" />
              ))}
            </>
            <GridAlpha.Item rowStart={2} rowEnd={2} columnStart={1} columnEnd={5}>
              <AttachedDocuments attachmentDocuments={languageProficiency.languageProficiencyData.documents} />
            </GridAlpha.Item>
          </GridAlpha>
        </Flex>
      )}
    </DetailsCard>
  );
}
