import { Flex, Table, Tag, ButtonLink, Text } from '@applyboard/crystal-ui';
import { Application, SortableFields } from './../data/useApplications';
import { ChevronRightOutlineIcon } from './../../../icons';
import { Flag } from './../../../components/Flag';
import { useSearchResult } from './../context';
import { formatDate } from '~/utils';
import { useFilterField } from './../hooks/useFilterField';

const columnMappings: Record<string, SortableFields> = {
  intakeTerm: 'programIntake.program.name',
  '-intakeTerm': '-programIntake.program.name',
  submittedAt: 'submittedAt',
  '-submittedAt': '-submittedAt',
  status: 'status',
  '-status': '-status',
};

export function ApplicationsTable() {
  const sortFilter = useFilterField('sort', 'SearchResult');
  const { applications, isFetchingApplications } = useSearchResult();

  return (
    <Flex direction="column">
      <Table
        onSort={(columns) => {
          if (columns.length === 0) return sortFilter.setValue(undefined);
          const column = columns[0];
          sortFilter.setValue(columnMappings[column.desc ? `-${column.id}` : `${column.id}`]);
        }}
        columns={[
          {
            accessorKey: 'id',
            header: 'App. ID',
          },
          {
            accessorKey: 'applicantName',
            header: 'Applicant Name',
            cell: (application: Application) => {
              const applicant = application.applicant;
              const middleName = applicant.middleName ? ` ${applicant.middleName}` : '';
              const familyName = applicant.familyName ? ` ${applicant.familyName}` : '';
              return <Table.TextCell>{`${applicant.givenName}${middleName}${familyName}`}</Table.TextCell>;
            },
          },
          {
            accessorKey: 'nationality',
            header: 'Nationality',
            cell: (application: Application) => {
              return (
                <Table.Cell>
                  <Flex align="center">
                    <Flag nationalityCode={application.applicant.nationality.alpha2Code} />
                    <Table.TextCell>{application.applicant.nationality.alpha3Code || ''}</Table.TextCell>
                  </Flex>
                </Table.Cell>
              );
            },
          },
          {
            enableSorting: true,
            accessorKey: 'intakeTerm',
            header: 'Program Intake',
            cell: (application: Application) => {
              return (
                <Flex direction="column" gap={1} p={2} align="start">
                  <Text as="div" contrast="high" id="" intent="secondary" variant="bodyM" overflow="ellipsis">
                    {application.programIntake.program.name}
                  </Text>
                  <Tag intent="secondary" size="sm">
                    {application.programIntake.intakeTerm.name}
                  </Tag>
                </Flex>
              );
            },
          },
          {
            enableSorting: true,
            accessorKey: 'submittedAt',
            header: 'Date Submitted',
            cell: (application: Application) => {
              return (
                <Table.TextCell>{formatDate(new Date(application.submittedAt), { month: 'short' })}</Table.TextCell>
              );
            },
          },
          {
            enableSorting: true,
            accessorKey: 'status',
            header: 'Status',
            cell: (application: Application) => {
              return (
                <Tag icon="fill" intent={application.status.intent} size="sm">
                  {application.status.label}
                </Tag>
              );
            },
          },
          {
            accessorKey: 'link',
            header: '',
            cell: (application: Application) => {
              return (
                <ButtonLink
                  aria-label="Application Link"
                  emphasis="transparent"
                  href={`/applications/${application.id}`}
                  intent="secondary"
                  leadIcon={() => {
                    return <ChevronRightOutlineIcon size="md" />;
                  }}
                  size="sm"
                  width="hug"
                />
              );
            },
          },
        ]}
        data={applications}
        loading={isFetchingApplications}
      />
    </Flex>
  );
}
