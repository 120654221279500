import { useEffect, useState } from 'react';
import { UnBoxArrayType } from '~/utils';
import { StaticProps } from '../context';
import { Program } from '../data/usePrograms';
import { queryParametersSchema, QueryParameters, QueryParametersKey, QueryParametersValue } from '../schema';

export type FilterSummaryItem<Key extends QueryParametersKey = QueryParametersKey> = {
  key: string;
  label: string;
  filterKey: Key;
  value: UnBoxArrayType<QueryParametersValue<Key>>;
};

interface useFilterSummaryProps {
  programs: Array<Program>;
  staticProps: StaticProps;
  queryParameters: QueryParameters;
}
export const useFilterSummary = ({ queryParameters, staticProps, programs }: useFilterSummaryProps) => {
  const [state, setState] = useState<FilterSummaryItem[]>([]);

  useEffect(() => {
    setState((currentFilterSummary) => {
      const newUids: string[] = [];
      const existingKeys = currentFilterSummary.map((fs) => fs.key);
      let summaries: FilterSummaryItem[] = [...currentFilterSummary];
      queryParametersSchema.parameters.forEach((parameter) => {
        if (!parameter.getSummaryItems) return;
        const value = parameter.getValue({ staticProps, queryParameters });
        const defaultValue = parameter.getDefaultValue({ initialQueryParameters: staticProps.initialQueryParameter });
        const result = parameter.getSummaryItems({
          value,
          defaultValue,
          staticProps,
          programs,
          queryParameters,
        });
        if (!result || result.length === 0) return;

        result.forEach((nfs) => newUids.push(nfs.key));
        summaries = [...summaries, ...result.filter((nfs) => !existingKeys.includes(nfs.key))];
      });
      return summaries.filter((efs) => newUids.includes(efs.key));
    });
  }, [staticProps, queryParameters, programs]);

  return { filterSummaries: state };
};
