import styled from '@emotion/styled';
import { MultiCombobox as CyMultiCombobox, MultiComboboxProps } from '@applyboard/crystal-ui';

export function MultiCombobox(props: MultiComboboxProps) {
  return (
    <Styles>
      <CyMultiCombobox {...props} />
    </Styles>
  );
}
MultiCombobox.Option = CyMultiCombobox.Option;

const Styles = styled.div({
  '& button': {
    backgroundColor: '#f6f8fe',
    border: '0',
  },
});
