import React from 'react';
import { TextInput } from '@applyboard/crystal-ui';
import { useFilterField } from './../../hooks/useFilterField';

export function QueryFilter() {
  const { setValue, value } = useFilterField('searchQuery', 'PrimaryFilter');
  const [termValue, setTermValue] = React.useState(value || '');
  React.useEffect(() => {
    setTermValue(value || '');
  }, [value]);

  return (
    <TextInput
      helpText=""
      aria-label="Applications Search"
      intent="secondary"
      onChange={setTermValue}
      placeholder="Search through applications..."
      size="md"
      type="search"
      value={termValue}
      onKeyDown={(key) => {
        if (key === 'Enter') {
          setValue(termValue);
        }
      }}
    />
  );
}
