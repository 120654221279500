import { DesktopLayout } from './layouts/DesktopLayout';
import { ApplicationContextProvider } from './context';
import { LoadingPage } from '~/pages/LoadingPage';
import { ErrorPage } from '~/pages/ErrorPage';
import { useWindowDocumentTitle } from '~/lib/useWindowDocumentTitle';
import { useApplicationsPageProps } from './data/useApplicationsPageProps';

export function ApplicationsPage() {
  useWindowDocumentTitle('Applications');
  const { initialProps, isLoadingInitialProps, initialPropsError } = useApplicationsPageProps();
  if (initialPropsError) {
    return <ErrorPage errorCode={500} customMessage="Unable to fetch page props"></ErrorPage>;
  }
  if (isLoadingInitialProps || !initialProps) {
    return <LoadingPage />;
  }
  return (
    <ApplicationContextProvider initialProps={initialProps}>
      <DesktopLayout />
    </ApplicationContextProvider>
  );
}
