import { QueryParameterSchema } from './types';
import { tryParseNumber } from '~/utils';

export const pageSizeParameter: QueryParameterSchema<'pageSize'> = {
  getValue: ({ queryParameters: queryParams }) => queryParams.pageSize,
  getDefaultValue: ({ initialQueryParameters }) => initialQueryParameters.pageSize || 20,

  toUrlSearchParams: ({ value }) => ({ 'page[size]': `${value}` }),
  toSearchQuery: ({ value }) => ({ 'page[size]': value }),
  fromUrlSearchParams: ({ urlSearchParams, defaultValue }) => ({
    pageSize: tryParseNumber(urlSearchParams.get('page[size]')) || defaultValue,
  }),
  sanitize: ({ staticProps, value, defaultValue }) => {
    if (value && staticProps.referenceData.pageSizes.some((size) => size === value)) return { pageSize: value };
    return { pageSize: defaultValue };
  },
};
