import React from 'react';
import { request, RequestParams } from './request';
import { useAuth } from '~/lib/useAuth';

export function useRequest() {
  const auth = useAuth();

  return React.useCallback(
    <T = unknown>(endpoint: string, params: RequestParams<T>) => {
      return request(endpoint, { ...params, authorization: auth.getAccessToken() });
    },
    [auth]
  );
}
