import { SvgIcon, SvgIconProps } from './SvgIcon';

export function PersonListOutline(props: Omit<SvgIconProps, 'children'>) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9984 3C9.23694 3 6.99836 5.23858 6.99836 8C6.99836 10.7614 9.23694 13 11.9984 13C14.7598 13 16.9984 10.7614 16.9984 8C16.9984 5.23858 14.7598 3 11.9984 3ZM8.99836 8C8.99836 6.34315 10.3415 5 11.9984 5C13.6552 5 14.9984 6.34315 14.9984 8C14.9984 9.65685 13.6552 11 11.9984 11C10.3415 11 8.99836 9.65685 8.99836 8Z"
        fill="#1E6DEB"
      />
      <path
        d="M2.05321 18.6733C2.89478 16.2383 4.75226 14.2513 7.14534 13.0967L8.6636 14.6149C6.39998 15.4921 4.66787 17.2307 3.9435 19.3266L3.61685 20.2718L1.72656 19.6185L2.05321 18.6733Z"
        fill="#1E6DEB"
      />
      <path d="M12.9984 16.5L13.9984 14H9.99836L10.9984 16.5L9.99836 20H13.9984L12.9984 16.5Z" fill="#1E6DEB" />
      <path d="M16.9984 15H22.9984V13H16.9984V15Z" fill="#1E6DEB" />
      <path d="M22.9984 19H16.9984V17H22.9984V19Z" fill="#1E6DEB" />
      <path d="M16.9984 23H22.9984V21H16.9984V23Z" fill="#1E6DEB" />
    </SvgIcon>
  );
}
