import { useCallback, useMemo } from 'react';
import { useSearchParams, useLocation } from 'react-router-dom';

import { QueryParameters, queryParametersSchema } from './../schema';
import { StaticProps } from './../context';

export const useSearchHistory = (staticProps: StaticProps) => {
  const [, setURLSearchParams] = useSearchParams();
  const { search } = useLocation();

  const urlRnd = useMemo(() => new URLSearchParams(search).get('r'), [search]);
  const push = useCallback(
    (rnd: string, params: QueryParameters) => {
      const paramsObject: Record<string, string> = {};
      queryParametersSchema.parameters.forEach((parameter) => {
        if (!parameter.toUrlSearchParams) return;
        const value = parameter.getValue({
          queryParameters: params,
          staticProps,
        });
        const defaultValue = parameter.getDefaultValue({
          initialQueryParameters: staticProps.initialQueryParameter,
        });
        const result = parameter.toUrlSearchParams({
          value,
          staticProps,
          defaultValue,
          queryParameters: params,
        });
        if (!result) return;
        Object.assign(paramsObject, result);
      });

      const queryParamString = `?${generateUrl({ ...paramsObject, r: rnd })}`;
      setURLSearchParams(queryParamString);
    },
    [staticProps, setURLSearchParams]
  );

  const parse = useCallback(() => parseUrl(search, staticProps), [search, staticProps]);

  return { urlRnd, push, parse };
};

const parseUrl = (search: string, staticProps: StaticProps) => {
  const urlSearchParams = new URLSearchParams(search);

  const result = { ...staticProps.defaultQueryParameters };

  queryParametersSchema.parameters.forEach((parameter) => {
    if (!parameter.fromUrlSearchParams) return;
    const params = parameter.fromUrlSearchParams({
      staticProps,
      urlSearchParams: urlSearchParams,
      defaultValue: parameter.getDefaultValue({ initialQueryParameters: staticProps.initialQueryParameter }),
    });
    if (!params) return;
    Object.assign(result, params);
  }, {} as QueryParameters);

  return result;
};

const generateUrl = (paramsObject: Record<string, string>) => {
  return Object.entries(paramsObject)
    .filter(([, v]) => !!v)
    .map(([k, v]) => `${k}=${v}`)
    .join('&');
};
