import { ButtonLink, Flex } from '@applyboard/crystal-ui';
import { DocumentCheckOutlineIcon } from '@applyboard/ui-icons';
import { LabelValue } from '~/components/uie-fix/LabelValue';

import { AttachmentDocument } from '../data/useApplication';

type AttachedDocumentsProps = {
  showTitle?: boolean;
  attachmentDocuments: AttachmentDocument[];
};
export function AttachedDocuments(props: AttachedDocumentsProps) {
  const { showTitle, attachmentDocuments } = props;

  return (
    <>
      {attachmentDocuments.map((attachmentDocument) => (
        <Flex key={attachmentDocument.fileName} gap={6} direction="column">
          {showTitle && <LabelValue variant="bodyL" label="SUPPORTING DOC" value={attachmentDocument.type.name} />}
          <ButtonLink
            aria-label={`Download ${attachmentDocument.fileName} document`}
            emphasis="highlighted"
            href={attachmentDocument.downloadUrl!}
            intent={attachmentDocument.validationStatus === 'VALID' ? 'positive' : 'primary'}
            size="lg"
            leadIcon={DocumentCheckOutlineIcon}
            width="fill"
            mode="override_user_choice_and_open_in_new_tab"
          >
            {attachmentDocument.fileName}
          </ButtonLink>
        </Flex>
      ))}
    </>
  );
}
