import React from 'react';

const FourOFour = () => (
  <>
    <h2>ERROR 404</h2>
    <h1>Page Not Found</h1>
    <p>The page you&apos;re looking for doesn&apos;t exist.</p>
  </>
);

export default FourOFour;
