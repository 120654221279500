import React from 'react';

const FiveHundred = () => (
  <>
    <h2>ERROR 500</h2>
    <h1>We&apos;re Sorry, Something Went Wrong</h1>
    <p>If you are the application owner check the logs for more information.</p>
  </>
);

export default FiveHundred;
