import React from 'react';
import styled from '@emotion/styled';
import { Accordion, Flex } from '@applyboard/crystal-ui';
import { IconType } from '../../utils';

export type ExpandableCardProps = {
  icon: IconType;
  title: string;
  children: React.ReactNode;
  value: Array<string>;
  onChange: (value: string | string[]) => void;
};

export function ExpandableCard(props: ExpandableCardProps) {
  return (
    <VisualBugFix>
      <Accordion onChange={props.onChange} value={props.value}>
        <Accordion.Item autoOpen={true} id={props.title} icon={props.icon} title={props.title}>
          <Flex p={4} direction="column">
            {props.children}
          </Flex>
        </Accordion.Item>
      </Accordion>
    </VisualBugFix>
  );
}

const VisualBugFix = styled.div({
  // button
  '& > div > div > button': {
    padding: '20px',
  },
});
