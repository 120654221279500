import React from 'react';
import { IconType } from '~/utils';
import { ExpandableCard } from '~/components/uie-fix/ExpandableCard';
import { ExpandableComponent, useApplicationDetailsCardsState } from '../context/ApplicationDetailsCardsStateProvider';

export type DetailsCardProps = {
  icon: IconType;
  title: ExpandableComponent;
  children: React.ReactNode;
};

export function DetailsCard({ children, icon, title }: DetailsCardProps) {
  const { cardsState, setCardState } = useApplicationDetailsCardsState();

  const onChange = React.useCallback(
    (value: string | string[]) => {
      // This seems to be of type Array<string> at all times.
      setCardState(title, value.includes(title));
    },
    [setCardState, title]
  );

  return (
    <ExpandableCard icon={icon} title={title} value={cardsState[title] ? [title] : []} onChange={onChange}>
      {children}
    </ExpandableCard>
  );
}
