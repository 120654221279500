import { Flex, Heading, Tag, Divider, Card, Text } from '@applyboard/crystal-ui';

import { Application } from '../data/useApplication';
import { formatCurrency, formatDate } from '~/utils';

function AttributesView(props: { items: Array<{ label: string; value: string }> }) {
  return (
    <>
      {props.items.map((item, idx) => (
        <Flex key={item.label} gap={3} direction="row" pr={2}>
          <Flex direction="row" gap={1} aria-describedby={item.label}>
            <Text id={item.label} variant="labelM">
              {item.label}
            </Text>
            <Text variant="bodyM" aria-labelledby={item.label}>
              {item.value}
            </Text>
          </Flex>
          {idx !== props.items.length - 1 && <Divider direction="vertical" />}
        </Flex>
      ))}
    </>
  );
}

type ProgramDetailsProps = { application: Application };
export function ProgramDetails(props: ProgramDetailsProps) {
  const { application } = props;
  const { program, campus, intakeTerm } = application.programIntake;
  return (
    <Card emphasis="highlighted">
      <Flex direction="column">
        <Flex gap={3} align="center">
          <Heading level={2}>{program.name}</Heading>
          <Tag intent="secondary" size="md">
            {intakeTerm.name}
          </Tag>
        </Flex>
        <Divider direction="horizontal" />
        <Flex key={program.id} gap={1} align="end" direction="row">
          <AttributesView
            items={[
              { label: 'Program Code', value: program.code },
              { label: 'Campus', value: campus.name },
              { label: 'Application ID', value: application.id },
              { label: 'Date Submitted', value: formatDate(new Date(application.submittedAt)) },
              { label: 'Application Fee Status', value: application.applicationFee.feeStatus.name },
              { label: 'Amount Paid', value: formatCurrency(application.applicationFee.feeAmount) },
            ]}
          />
        </Flex>
      </Flex>
    </Card>
  );
}
