import React from 'react';
import { ErrorPage } from '~/pages/ErrorPage';
import { LoadingPage } from '~/pages/LoadingPage';
import { useAppProps as baseUseAppProps, AppProps as BaseAppProps } from '~/data/useAppProps';

const context = React.createContext<BaseAppProps | undefined>(undefined);

export type AppPropsProps = { children: React.ReactNode };
export function AppProps(props: AppPropsProps) {
  const { appProps, isAppPropsLoading, isAppPropsError } = baseUseAppProps();

  if (isAppPropsLoading) return <LoadingPage />;
  if (!isAppPropsLoading && (isAppPropsError || !appProps)) return <ErrorPage errorCode={500} />;

  return <context.Provider value={appProps}>{props.children}</context.Provider>;
}

export function useAppProps() {
  const state = React.useContext(context);
  if (!state) throw new Error('useAppProps should be used within <AppProps/>');
  return state;
}
