import { config } from '../config';
import { useQuery } from '@tanstack/react-query';
import { SpaPropsResponse } from '@ab/ams-school-api/types/private/v1';

import { request } from '../lib/useRequest';

export type AppProps = {
  oauth: {
    authority: string;
    clientId: string;
    logoutUri: string;
  };
};

export function useAppProps() {
  const { data, isLoading, isError } = useQuery(
    ['v1_spa_props'],
    async (): Promise<AppProps> => {
      const response = await request<SpaPropsResponse>(`${config.apiHost}/private/v1/spa_props`, {
        method: 'GET',
        isExpectedResponse,
      });

      return {
        oauth: {
          authority: `https://cognito-idp.${response.region}.amazonaws.com/${response.userPoolId}`,
          clientId: response.userPoolClientId,
          logoutUri: `https://${response.userPoolDomainName}.auth.${response.region}.amazoncognito.com/logout`,
        },
      };
    },
    {
      refetchOnMount: false,
      keepPreviousData: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
    }
  );

  return {
    appProps: data,
    isAppPropsError: isError,
    isAppPropsLoading: isLoading,
  };
}

function isExpectedResponse(res: unknown): res is SpaPropsResponse {
  return Boolean(res && typeof res === 'object');
}
