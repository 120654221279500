import React from 'react';

const FourTwoTwo = () => (
  <>
    <h2>ERROR 422</h2>
    <h1>Change Rejected</h1>
    <p>Maybe you tried to change something you didn&apos;t have access to?</p>
    <p>If you are the application owner check the logs for more information.</p>
  </>
);

export default FourTwoTwo;
