import { Flex, Heading } from '@applyboard/crystal-ui';
import { PassportOutlineIcon } from '@applyboard/ui-icons';
import { LabelValue } from '~/components/uie-fix/LabelValue';

import { formatDate } from '~/utils';
import { DetailsCard } from './DetailsCard';
import { AttachedDocuments } from './AttachedDocuments';
import type { StatusAndCitizenship } from '../data/useApplication';

type StatusAndCitizenshipProps = { statusAndCitizenship: StatusAndCitizenship };
export function StatusAndCitizenship(props: StatusAndCitizenshipProps) {
  const { statusAndCitizenship } = props;

  return (
    <DetailsCard icon={PassportOutlineIcon} title="Status and Citizenship">
      <Flex justify="start" direction="column" gap={8}>
        <Heading level={4} variant="titleS">
          Citizenship Information
        </Heading>
        <Flex gap={8}>
          <LabelValue label="EXPIRY DATE" value={formatDate(new Date(statusAndCitizenship.passportExpiryDate))} />
          <LabelValue label="PASSPORT NUMBER" value={statusAndCitizenship.passportNumber} />
        </Flex>
        <AttachedDocuments attachmentDocuments={statusAndCitizenship.documents} showTitle />
        <Heading level={4} variant="titleS">
          Status Information
        </Heading>
        <LabelValue label="STATUS IN CANADA" value={statusAndCitizenship.destinationCountryStatus.name} />
      </Flex>
    </DetailsCard>
  );
}
