import React from 'react';
import { Outlet } from 'react-router-dom';

import { AppBar } from './AppBar';
import SchoolLogo from './SchoolLogo.svg';
import { LoadingPage } from '../../pages/LoadingPage';

import { FixedAppbar } from './styles';
import { Box, Flex } from '@applyboard/crystal-ui';
import { useAuth } from '~/lib/useAuth';
import FourOOne from '~/pages/ErrorPage/401';

export function Layout() {
  const auth = useAuth();

  if (auth.isLoading) return <LoadingPage />;
  if (!auth.isAuthenticated) return <FourOOne />;

  return (
    <Flex align="stretch" as="div" direction="column" gap={8} grow={0} justify="evenly" px={10}>
      {/* AppBar */}
      <FixedAppbar data-testid="header">
        <AppBar title="ACME University" logo={SchoolLogo} />
      </FixedAppbar>
      {/* Content */}
      <Box pt={14}>
        <Outlet />
      </Box>
    </Flex>
  );
}
