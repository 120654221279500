import { Flex, Pagination } from '@applyboard/crystal-ui';
import { useFilterField } from './../hooks/useFilterField';
import { useSearchResult, useStaticProps } from './../context';

export function ApplicationsPagination() {
  const pageNumberFilter = useFilterField('pageNumber', 'Pagination');
  const pageSizeFilter = useFilterField('pageSize', 'Pagination');
  const { totalApplications } = useSearchResult();
  const staticProps = useStaticProps();

  return (
    <Flex align="center" pt={10} grow={1}>
      <Flex.Item grow={1}>
        <Pagination
          numItems={totalApplications}
          currentPage={pageNumberFilter.value}
          pageSize={pageSizeFilter.value}
          onPageSizeChange={(pageSize) => pageSizeFilter.setValue(pageSize)}
          disabled={totalApplications <= 0}
          onPageChange={(pageNumber) => pageNumberFilter.setValue(pageNumber)}
          variant="items"
          pageSizeOptions={staticProps.referenceData.pageSizes}
        />
      </Flex.Item>
    </Flex>
  );
}
