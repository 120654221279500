export function formatDate(date: Date | undefined | null, options: Intl.DateTimeFormatOptions = {}): string {
  if (!date) {
    return '';
  }

  const formattedDate = date.toLocaleDateString('en-CA', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    ...options,
  });

  return formattedDate;
}
