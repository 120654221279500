import { QueryParameterSchema } from './types';
import { tryParseNumber } from '~/utils';

export const pageNumberParameter: QueryParameterSchema<'pageNumber'> = {
  getDefaultValue: () => 1,
  getValue: ({ queryParameters: queryParams }) => queryParams.pageNumber,

  toUrlSearchParams: ({ value }) => ({ 'page[number]': `${value}` }),
  toSearchQuery: ({ value }) => ({ 'page[number]': value }),
  fromUrlSearchParams: ({ urlSearchParams, defaultValue }) => {
    const pageOffset = tryParseNumber(urlSearchParams.get('page[number]'));
    const pageNumber = pageOffset !== null ? pageOffset : defaultValue;
    return { pageNumber };
  },
};
