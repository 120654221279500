import React from 'react';
import { UnBoxArrayType } from '~/utils';
import { ApplicationsPageInitialProps, InitialQueryParameter, ReferenceData } from './../data/useApplicationsPageProps';
import {
  QueryParametersValue,
  QueryParametersKey,
  QueryParameters,
  QueryParametersSchema,
  queryParametersSchema,
} from '../schema';

export type StaticProps = {
  initialQueryParameter: InitialQueryParameter;
  referenceData: ReferenceData;
  defaultQueryParameters: QueryParameters;
};
export type FilterSummaryItem<Key extends QueryParametersKey> = {
  key: string;
  label: string;
  filterKey: Key;
  value: UnBoxArrayType<QueryParametersValue<Key>>;
};

const staticPropsContext = React.createContext<StaticProps | undefined>(undefined);

export interface StaticPropsContextProps {
  initialProps: ApplicationsPageInitialProps;
  children: React.ReactNode;
}
export function StaticPropsContextProvider(props: StaticPropsContextProps) {
  const value = React.useMemo(
    () => ({
      referenceData: props.initialProps.referenceData,
      initialQueryParameter: props.initialProps.initialQueryParameters,
      defaultQueryParameters: getDefaultQueryParameters(props.initialProps),
    }),
    [props.initialProps]
  );
  return <staticPropsContext.Provider value={value}>{props.children}</staticPropsContext.Provider>;
}

export function useStaticProps() {
  const value = React.useContext(staticPropsContext);
  if (value === undefined) {
    throw new Error('useStaticProps must be used within a StaticPropsContextProvider');
  }
  return value;
}

function getDefaultQueryParameters({ initialQueryParameters }: ApplicationsPageInitialProps) {
  type PartialRecord<K extends keyof QueryParametersSchema, T> = { [P in K]?: T };
  const defaultValues: PartialRecord<keyof QueryParametersSchema, unknown> = {};
  queryParametersSchema.parameters.forEach((parameter) => {
    const value = parameter.getDefaultValue({ initialQueryParameters });
    defaultValues[parameter.key] = value;
  });
  return defaultValues as QueryParameters;
}
