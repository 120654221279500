import React from 'react';

const EXPANDABLE_COMPONENTS = [
  'Applicant Information',
  'Contact Information',
  'Status and Citizenship',
  'Education History',
  'Language Proficiency',
  'Other Required Documents',
] as const;

export type ExpandableComponent = (typeof EXPANDABLE_COMPONENTS)[number];

function generateExpandableComponentsState(expanded: boolean): Record<string, boolean> {
  return EXPANDABLE_COMPONENTS.reduce((p, c) => ({ ...p, [c]: expanded }), {});
}

const CardsStateContext = React.createContext<
  | {
      cardsState: Record<ExpandableComponent, boolean>;
      setCardState: (componentName: ExpandableComponent, expanded: boolean) => void;
      setAllCardsState: (expanded: boolean) => void;
      allExpanded: boolean;
      allCollapsed: boolean;
    }
  | undefined
>(undefined);

interface CardsStateContextProps {
  expanded?: boolean;
  children: React.ReactNode;
}

export function ApplicationDetailsCardsStateProvider({ expanded = true, children }: CardsStateContextProps) {
  const [cardsState, setCardsState] = React.useState<Record<ExpandableComponent, boolean>>(() =>
    generateExpandableComponentsState(expanded)
  );
  function setCardState(id: ExpandableComponent, expanded: boolean) {
    setCardsState({ ...cardsState, [id]: expanded });
  }
  function setAllCardsState(expanded: boolean) {
    setCardsState(generateExpandableComponentsState(expanded));
  }

  function verifyAllCardsState(state: boolean) {
    return !Object.keys(cardsState).some((v) => cardsState[v as ExpandableComponent] !== state);
  }
  const allExpanded = verifyAllCardsState(true);
  const allCollapsed = verifyAllCardsState(false);

  return (
    <CardsStateContext.Provider value={{ cardsState, setCardState, setAllCardsState, allExpanded, allCollapsed }}>
      {children}
    </CardsStateContext.Provider>
  );
}

export function useApplicationDetailsCardsState() {
  const value = React.useContext(CardsStateContext);
  if (value === undefined) {
    throw new Error(
      'The useApplicationDetailsCardsState hook must be used within an ApplicationDetailsCardsStateProvider'
    );
  }
  return value;
}
