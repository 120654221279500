import React from 'react';

import { SessionContextProvider } from './SessionContext';
import { StaticPropsContextProvider } from './StaticPropsContext';
import { SearchResultContextProvider } from './SearchResultContext';
import { QueryParameterContextProvider } from './QueryParameterContext';
import { ApplicationsPageInitialProps } from '../data/useApplicationsPageProps';

interface SearchContextProvider {
  children: React.ReactNode;
  initialProps: ApplicationsPageInitialProps;
}
export function ApplicationContextProvider(props: SearchContextProvider) {
  return (
    <SessionContextProvider>
      <StaticPropsContextProvider initialProps={props.initialProps}>
        <QueryParameterContextProvider>
          <SearchResultContextProvider>{props.children}</SearchResultContextProvider>
        </QueryParameterContextProvider>
      </StaticPropsContextProvider>
    </SessionContextProvider>
  );
}
