import React from 'react';
import { ButtonLink } from '@applyboard/crystal-ui';

const FourOOne = () => (
  <>
    <h2>ERROR 401</h2>
    <h1>Access Denied</h1>
    <p>You are not authorized to access this page.</p>
    <ButtonLink emphasis="outlined" href="/login">
      ← Back to Log In
    </ButtonLink>
  </>
);

export default FourOOne;
