import { QueryParameterSchema } from './types';

export const searchQueryParameter: QueryParameterSchema<'searchQuery'> = {
  getDefaultValue: () => '',
  getValue: ({ queryParameters }) => queryParameters.searchQuery,

  fromUrlSearchParams: ({ urlSearchParams }) => ({ searchQuery: urlSearchParams.get('filter[q]') || '' }),
  toUrlSearchParams: ({ value }) => ({ 'filter[q]': value }),
  toSearchQuery: ({ defaultValue, value }) => ({ 'filter[q]': value || defaultValue }),
};
