import { uidNoDash } from '~/utils';
import React, { createContext, useContext, useState } from 'react';

const sessionContext = createContext<string | undefined>(undefined);

export function SessionContextProvider({ children }: { children: React.ReactNode }) {
  const [sessionId] = useState<string>(() => uidNoDash());
  return <sessionContext.Provider value={sessionId}>{children}</sessionContext.Provider>;
}

export function useSession() {
  const value = useContext(sessionContext);
  if (value === undefined) {
    throw new Error('useSession must be used within a SessionContextProvider');
  }
  return value;
}
