import styled from '@emotion/styled';
import { Size, Intent } from '~/utils';

export type SvgIconProps = {
  size?: Size;
  intent?: Intent;
  'aria-label'?: string;
  children: React.ReactNode;
};

export function SvgIcon({ size = 'md', ...props }: SvgIconProps) {
  return (
    <StyledSvg
      aria-hidden={props['aria-label'] ? undefined : true}
      aria-label={props['aria-label']}
      role="img"
      size={size}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      intent={props.intent}
    >
      {props.children}
    </StyledSvg>
  );
}

const StyledSvg = styled.svg<{
  size: Size;
  intent?: Intent;
}>((props) => {
  const fillColor = getIconColor(props.intent);
  return {
    fill: fillColor,
    height: getIconSize(props.size),
    width: getIconSize(props.size),
  };
});

const getIconColor = (intent?: Intent) => {
  if (!intent) return 'currentColor';
  switch (intent) {
    case 'magic':
      return 'Magic';
    case 'negative':
      return 'Negative';
    case 'positive':
      return 'Positive';
    case 'primary':
      return 'Primary';
    case 'secondary':
      return 'Secondary';
    case 'warning':
      return 'Warning';
    default:
      return 'currentColor';
  }
};

const getIconSize = (sizeCode: Size = 'md') => {
  switch (sizeCode) {
    case 'sm':
      return '16px';
    case 'md':
      return '24px';
    case 'lg':
      return '32px';
    case 'xl':
      return '48px';
    default:
      throw new Error(`Size code not supported: ${sizeCode}`);
  }
};
