import React from 'react';
import { PersonOutlineIcon } from '@applyboard/ui-icons';
import { Flex, Heading, Text, GridAlpha } from '@applyboard/crystal-ui';
import { LabelValue } from '~/components/uie-fix/LabelValue';
import { DetailsCard } from './DetailsCard';

import { formatDate } from '~/utils';
import { Flag } from '~/components/Flag';
import { Applicant, ApplicantAddress } from '../data/useApplication';

function ApplicantInfo(props: { applicant: Applicant }) {
  const { applicant } = props;
  return (
    <GridAlpha rows="repeat(3, auto)" columnGap={2} rowGap={6} columns="repeat(3, 1fr)">
      <LabelValue label="GIVEN NAME" value={applicant.givenName} />
      <LabelValue label="FAMILY NAME" value={applicant.familyName || '--'} />
      <LabelValue label="MIDDLE NAME" value={applicant.middleName || '--'} />

      <LabelValue label="DATE OF BIRTH" value={formatDate(new Date(applicant.dateOfBirth))} />
      <LabelValue label="GENDER" value={applicant.gender.name} />
      <LabelValue
        label="NATIONALITY"
        value={applicant.nationality.nationalityLabel}
        icon={<Flag height={10} nationalityCode={applicant.nationality.alpha2Code} />}
      />
    </GridAlpha>
  );
}
function AddressDetails(props: { heading: string; address: ApplicantAddress }) {
  const { address } = props;
  return (
    <Flex gap={8} direction="column">
      <Heading level={4} variant="titleS">
        {props.heading}
      </Heading>
      <GridAlpha rows="repeat(1, auto)" columnGap={2} rowGap={6} columns="repeat(3, 1fr)">
        <GridAlpha.Item rowStart={1} columnStart={1} columnEnd={2}>
          <LabelValue label="COUNTRY" value={address.country.countryLabel} />
        </GridAlpha.Item>
        <GridAlpha.Item rowStart={2} columnStart={1} columnEnd={1}>
          <LabelValue label="ADDRESS LINE 1" value={address.addressLine1} />
        </GridAlpha.Item>
        <GridAlpha.Item rowStart={2} columnStart={2} columnEnd={2}>
          <LabelValue label="ADDRESS LINE 2" value={address.addressLine2 || 'N/A'} />
        </GridAlpha.Item>
        <GridAlpha.Item rowStart={3} columnStart={1} columnEnd={1}>
          <LabelValue label="CITY" value={address.city} />
        </GridAlpha.Item>
        <GridAlpha.Item rowStart={3} columnStart={2} columnEnd={2}>
          <LabelValue label="PROVINCE/STATE/AREA" value={address.province} />
        </GridAlpha.Item>
        <GridAlpha.Item rowStart={4} columnStart={1} columnEnd={2}>
          <LabelValue label="POSTAL/ZIP CODE" value={address.postalCode || 'N/A'} />
        </GridAlpha.Item>
      </GridAlpha>
    </Flex>
  );
}

type ApplicantInformationProps = { applicant: Applicant };
export function ApplicantInformation(props: ApplicantInformationProps) {
  const { applicant } = props;
  const isMailingAddressSameAsResidential = props.applicant.addresses.isMailingAddressSameAsResidential;

  return (
    <DetailsCard icon={PersonOutlineIcon} title="Applicant Information">
      <Flex gap={8} direction="column">
        <ApplicantInfo applicant={applicant} />
        <AddressDetails heading="Residential Address" address={applicant.addresses.residentialAddress} />
        {isMailingAddressSameAsResidential ? (
          <Text>Mailing address is the same as shipping address.</Text>
        ) : (
          <>
            <AddressDetails heading="Mailing Address" address={applicant.addresses.mailingAddress} />
          </>
        )}
      </Flex>
    </DetailsCard>
  );
}
